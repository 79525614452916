import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AppDataService } from './app-data.service';
import { faSignOutAlt, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { SecurityService } from './services/security/security.service';
import { Apollo } from 'apollo-angular';
import { ApolloService } from './services/apollo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  apollo: Apollo;

  constructor(
    public globalData: AppDataService,
    private router: Router,
    public securityService: SecurityService,
    private apolloService: ApolloService
  ) {
    this.apollo = inject(Apollo);
    if (!this.globalData.appConfig) {
      this.globalData.getAppConfig();
    }
  }

  @ViewChild('#openTicket') openTicket: any;

  title = 'cheetah-admin';
  faSignOutAlt = faSignOutAlt;
  faCaretDown = faCaretDown;

  showEnvs: boolean;
  year = new Date().getFullYear();

  ticket: any;
  escalateTooltip = 'Escalate Ticket';
  openedTickets: any[] = [];
  interactionText = '';
  isTicketsOpened = false;
  showSubmenu = [false, false, false, false, false];

  ngOnInit(): void {
    this.securityService.requestCurrentUser.subscribe((user: any) => {
      if (user) {
        this.apolloService.createClient();
      }
    });
  }

  logout(): void {
    this.securityService.cleanOauth();
    localStorage.removeItem('token');
    this.globalData.onLogout(true);
    this.apollo.getClient().resetStore();
    this.router.navigate(['/logged-out']);
  }

}
