<form [formGroup]="jobArgumentsForm">
  <div class="modal-header">
    <h1>Job arguments</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{errorMsg}}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div formArrayName="job_arguments">
      <div class="row"
        *ngFor="let item of jobArguments.controls; let pointIndex=index; first as isFirst; last as isLast"
        [formGroupName]="pointIndex">
        <div class="col-sm-4">
          <div class="form-group">
            <label *ngIf="isFirst">Name</label>
            <input type="text" formControlName="name" class="form-control" name="name">
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <label *ngIf="isFirst">Value</label>
            <input type="text" formControlName="value" class="form-control" name="value">
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group" [ngClass]="{'pt-4': isFirst}">
            <button type="button" class="btn btn-sm btn-danger" (click)="removeAttr(pointIndex)">
              <fa-icon [icon]="faTrash"></fa-icon>
            </button>
            <button type="button" class="btn btn-sm btn-primary" (click)="addAttr()" [hidden]="!isLast">
              <fa-icon [icon]="faPlus"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" type="submit" (click)="ok()">
      Run
    </button>
    <button class="btn btn-warning" type="button" (click)="cancel()">
      Cancel
    </button>
  </div>
</form>