import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommunicationsModalComponent } from '../communications-modal/communications-modal.component';
import { Choice, Filter, SearchFilters } from '../data/class';
import { Communication, DeliveryProtocol } from '../data/model';
import { CommunicationsService } from '../services/communications.service';
import { StatusHelperService } from '../services/helpers/status-helper.service';
import { TableHelperService } from '../services/helpers/table-helper.service';
import { faPlus, faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { UserPreferencesService } from '../services/user-preferences.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ChirpService } from '../services/chirp.service';
import { ToastrService } from 'ngx-toastr';
import { parseResponse } from '../data/parseResponseFunction';

@Component({
  selector: 'app-communication-management',
  templateUrl: './communication-management.component.html',
  styleUrls: ['./communication-management.component.scss']
})
export class CommunicationManagementComponent implements OnInit {

  limit: number;
  filter = new Filter();
  searchFilters: SearchFilters;
  statuses: Choice[];
  communications: Communication[];
  lock = false;
  isLoading = false;
  lengthToCompare: number;
  selectedComm: Communication;
  faPlus = faPlus;
  lockedTables = false;
  commId: number;
  deliveryProtocols: DeliveryProtocol[];
  statusHelper: any;
  faEdit = faEdit;
  faEye = faEye;
  showSearchFilter = false;

  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private tableHelperService: TableHelperService,
    private communicationsService: CommunicationsService,
    private title: Title,
    private activatedRoute: ActivatedRoute,
    private chirpService: ChirpService,
    private statusHelperService: StatusHelperService,
    private modalHelper: NgbModal,
    private userPreferences: UserPreferencesService,
    private toastr: ToastrService
    ) { }

  ngOnInit(): void {
    this.statusHelper = this.statusHelperService.getStatus('badge');
    this.communicationsService.getAllEndpointTypes().subscribe((response: any) => {
      this.searchFilters = {
        formName: 'communications_search',
        sections: [
          {
            key: 'firstRow',
            additionalRow: true
          },
          {
            key: 'secondRow',
            additionalRow: true
          }
        ],
        filters: [
          {
            name: 'search',
            placeholder: 'Keyword...',
          },
          {
            name: 'statuses',
            placeholder: 'Status',
            type: 'array',
            choices: this.tableHelperService.getStatuses('ENTITY'),
            nameProp: 'value',
            valueProp: 'value',
            inputType: 'dropdown',
            value: this.userPreferences.service.getPreference('communications.defaultFilterValues.status')
          },
          {
            name: 'segmentIds',
            type: 'array',
            inputType: 'segment-filter',
            singleLabel: 'Segment',
          },
          {
            name: 'endpointTypeNames',
            placeholder: 'Endpoint',
            type: 'array',
            choices: response.entity,
            nameProp: 'name',
            valueProp: 'name',
            inputType: 'dropdown',
            section: {key: 'firstRow' }
          },
          {
            name: 'communicationName',
            placeholder: 'Custom..',
            section: {key: 'firstRow' }
          },
          {
            name: 'communicationName',
            placeholder: 'Name',
            section: {key: 'firstRow' }
          },
          {
            name: 'ap',
            type: 'boolean',
            label: 'Require Preferences',
            section: {key: 'secondRow' }
          },
        ],
        searchCallback: () => { this.initNewSearch(); },
      };
      this.showSearchFilter = true;
    });
  }

  initFilter(): void {
    this.limit = 20;
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  setStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('ENTITY');
  }

  newCommunication(): void {
    const instance = this.modalHelper.open(CommunicationsModalComponent);
    instance.componentInstance.successEvent.subscribe(() => {
      this.initNewSearch();
      instance.close();
    });
  }

  communicationsModal(selectedComm: Communication): void{
    const instance = this.modalHelper.open(CommunicationsModalComponent);
    instance.componentInstance.communication = selectedComm;
    instance.componentInstance.successEvent.subscribe(() => {
      this.initNewSearch();
      instance.close();
    });
  }

  getCommunication(): void {
    this.communicationsService.getCommunication(this.activatedRoute.snapshot.params.id).subscribe((data: any) => {
      if (data.success) {
        this.selectedComm = data.entity;
        this.commId = this.selectedComm.id;
        this.title.setTitle(this.selectedComm.name);
        this.chirpService.getDeliveryProtocols(this.commId).subscribe((resp: any) => {
          this.deliveryProtocols = resp.entity;
        });
      }
    });
  }

  getCommunications(concat: boolean): void {
    this.filter = this.searchFilters.getFilters(this.filter);
    this.isLoading = true;
    this.communicationsService.getCommunications(this.filter).subscribe((data: any) => {
      if (concat) {
        this.communications = this.communications.concat(parseResponse(data));
      } else {
        this.selectedComm = undefined;
        this.communications = parseResponse(data);
      }
      this.lengthToCompare = parseResponse(data).length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.filter.offset += this.limit;
      this.lockedTables = parseResponse(data).length !== this.limit;
      this.lock = this.lockedTables;
      this.isLoading = false;
    }, () => {
      this.lockedTables = false;
      this.isLoading = false;
      this.lock = false;
      this.toastr.error('Error occured!');
    });
  }

  initNewSearch(): void {
    this.initFilter();
    this.getCommunications(false);
  }

  showCommunication(e, c: Communication): void {
    $('tr.active').removeClass('active');
    $(e.currentTarget).toggleClass('active');
    this.selectedComm = c;
  }

  show(): boolean {
    if (this.selectedComm !== undefined) {
      return true;
    }
    return false;
  }

}
