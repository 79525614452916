import { AppDataService } from './../../app-data.service';
import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { Choice, Filter, SearchFilters } from '../../data/class';
import { TableHelperService } from '../../services/helpers/table-helper.service';
import { MembersService } from '../../services/members.service';
import * as $ from 'jquery';
import { Member } from '../../data/model';
import { SecurityService } from './../../services/security/security.service';
import { UserPreferencesService } from './../../services/user-preferences.service';
import { ConfigurationService } from './../../services/configuration.service';
// import { SearchFilterComponent } from '../search-filter/search-filter/search-filter.component';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProspectiveModalComponent } from './../../prospective-modal/prospective-modal.component';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit {

  noMoreMembers = false;
  lengthToCompare: number;
  lock: boolean;
  isLoading: boolean;
  applyHasBeenClicked: boolean;
  selectedPersonId: number;
  selectedPerson: Member;
  limit: number;
  searchFilters: SearchFilters;
  members: Member[];
  filter = new Filter();
  type = 'members';
  allowJiraAccess: boolean;
  jiraLink: string;
  statuses: Choice[];
  numColumns: number;
  isCSR = this.appDataService.isCSR;
  isCSRManager = this.appDataService.isCSRManager;
  isPartner = this.appDataService.isPartner;
  isSSO: boolean;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private tableHelperService: TableHelperService,
    private membersService: MembersService,
    private securityService: SecurityService,
    private userPreferencesService: UserPreferencesService,
    private configurationService: ConfigurationService,
    // private searchFilter: SearchFilterComponent
    private appDataService: AppDataService,
    private modalHelper: NgbModal,
    private toastr: ToastrService,
    private router: Router,
  ) { }

  ngOnInit(): void {

    this.securityService.checkIsSSOInstance.subscribe((data: boolean) => {
      this.isSSO = data;

      this.numColumns = this.isSSO ? 8 : 9;
    });

    this.configurationService.getConfiguration('ENABLE_PROSPECTIVE_MEMBER').subscribe((configData: any) => {
      if (configData.success && configData.entity && configData.entity.length > 0) {
        this.appDataService.showProspective = configData.entity[0].cfgValue == 'true';
      } else {
        this.appDataService.showProspective = false;
      }
    });

    this.allowJiraAccess = this.userPreferencesService.service.getPreference('layout.allowJiraAccess');
    this.getConfiguration();

    this.searchFilters = {
      formName: 'memberSearch',
      title: 'Member Search',
      searchCallback: () => { this.initNewSearch(); },
      waitUntilApply: true,
      sections: [
        {
          name: 'Advanced Search',
          key: 'advancedSearch',
          open: null
        }
      ],
      filters: [
        {
          name: 'onlySegmentless',
          type: 'segmentless',
          label: 'Only Segment-less',
        },
        {
          name: 'segmentIds',
          type: 'array',
          inputType: 'segment-filter',
          singleLabel: 'Segment',
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('ENTITY'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
        },
        {
          name: 'profileID',
          placeholder: 'Profile ID',
          type: 'number',
        },
        {
          name: 'instrument',
          placeholder: 'Instrument',
        },
        {
          name: 'username',
          placeholder: 'Username',
        },
        {
          name: 'firstName',
          placeholder: 'First Name',
        },
        {
          name: 'lastName',
          placeholder: 'Last Name',
        },
        {
          name: 'dob',
          type: 'date',
          placeholder: 'DOB',
          section: {key: 'advancedSearch' },
        },
        {
          name: 'email',
          placeholder: 'Email',
          section: { key: 'advancedSearch' },
        },
        {
          name: 'phone',
          placeholder: 'Phone Number',
          section: { key: 'advancedSearch' },
        },
        {
          name: 'attributeName',
          placeholder: 'Attribute Name',
          section: { key: 'advancedSearch' },
        },
        {
          name: 'attributeValue',
          placeholder: 'Attribute Value',
          section: { key: 'advancedSearch' },
        },
        {
          name: 'search',
          placeholder: 'Keyword',
          section: { key: 'advancedSearch' },
        }
      ],
      addEntityButtons: [
        {
          icon: '',
          buttonText: 'Create Prospective Member',
          callback: () => { this.openProspectiveMemberModal(); },
          if: this.appDataService.showProspective === false,
          title: 'Create Prospective Member'
        }
      ]
    };

    this.statuses = this.tableHelperService.getStatuses('ENTITY');

  }

  initFilter(): void {
    this.limit = 20;
    this.filter.limit = this.limit;
    this.filter.offset = 0;
  }

  getConfiguration(): void {
    this.configurationService.getConfiguration('JIRA_SSO').subscribe((data: any) => {
      if (data.success && data.entity.length > 0) {
        this.jiraLink = data.entity[0].cfgValue;
      }
    });
  }

  showPerson(): boolean {
    return this.selectedPersonId !== undefined;
  }

  showMember(e: any, id: number): void {
    if (this.searchFilters.getFilters(this.filter).onlySegmentless) {
      this.router.navigate(['/forbidden']);
      return;
    }
    $('tr.active').removeClass('active');
    $(e.currentTarget).toggleClass('active');
    this.selectedPersonId = id;
  }

  initNewSearch(): void {
    this.applyHasBeenClicked = true;
    this.initFilter();
    this.getMembers(false);
  }

  getMembers(concat: boolean): void {
    if (this.applyHasBeenClicked) {
      this.filter = this.searchFilters.getFilters(this.filter);
      this.isLoading = true;
      this.membersService.getMembers(this.filter).subscribe((data: any) => {
        if (concat) {
          this.members = this.members.concat(data.entity);
        } else {
          this.selectedPersonId = undefined;
          this.members = data.entity;
        }
        this.lengthToCompare = data.entity.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lock = data.entity.length !== this.limit;
        this.isLoading = false;
      }, () => {
        this.isLoading = false;
        this.lock = false;
        this.toastr.error('Error occurred!');
      });
    }
  }

  navigateToTab(tabId: number): void {
    this.router.navigate(['/members/' + this.selectedPersonId], { fragment: tabId === 5 ? 'contacts' : 'accounts' }).finally(() => {
      this.router.onSameUrlNavigation = 'ignore'; // Restore config after navigation completes
    });
  }

  openProspectiveMemberModal(): void {
    const instance = this.modalHelper.open(ProspectiveModalComponent);
    instance.componentInstance.personId = this.selectedPersonId;
    instance.componentInstance.successEvent.subscribe(() => {
      //TODO
    });
  }
}
