import { AppDataService } from './../app-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProcessingActivityLogService {

  constructor(private http: HttpClient, private globalData: AppDataService) { }

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getProcessingLogs(params: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'processing-log/', this.setParams(params));
  }

  startProcessing(loadId: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'process/stage/' + loadId);
  }

  getProcessingHistoryForActivity(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'processing-log/activity-history/' + id);
  }

  updateNote(id, actId, note): any {
    return this.http.put<any>(this.globalData.baseUrl + 'processing-log/' + id + '/activity/' + actId , note, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getProcessingLogMessages(id: number, activityId: number): any {
    return this.http.get(this.globalData.baseUrl + 'processing-log/' + id + '/activity-messages/' + activityId);
  }

  findProcessingLogActivitiesForId(id: number, params: any): any {
    return this.http.get(this.globalData.baseUrl + 'processing-log/' + id, this.setParams(params));
  }
}
