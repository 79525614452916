import { DateHelperService } from 'src/app/services/helpers/date-helper.service';
import { Filter, SearchFilters } from './../../data/class';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';
import { ProcessingActivityLogService } from 'src/app/services/processing-activity-log.service';
import { faCalendar, faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from 'src/app/services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { parseResponse } from 'src/app/data/parseResponseFunction';

@Component({
  selector: 'app-processing-logs',
  templateUrl: './processing-logs.component.html',
  styleUrls: ['./processing-logs.component.scss']
})
export class ProcessingLogsComponent implements OnInit {

  filteredStatuses = [];
  filter: Filter = new Filter();
  dtInstance: any = {};
  limit = 20;
  processingLogs = [];
  lengthToCompare: number;
  lockedTables: boolean;
  lock: boolean;
  isLoading: boolean;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  faCalendar = faCalendar;
  fromDate: NgbDate;
  toDate: NgbDate;
  faTimes = faTimes;
  searchFilters: SearchFilters;
  faExclamationTriangle = faExclamationTriangle;

  constructor(
    private tableHelperService: TableHelperService,
    private title: Title,
    private toastr: ToastrService,
    private utilityService: UtilityService,
    private processingActivityLogService: ProcessingActivityLogService,
    private dateHelperService: DateHelperService
  ) { }

  tableHelper = this.tableHelperService;
  statuses = this.tableHelperService.getStatuses('ENTITY');

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'processing-logs-search',
      filters: [
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('ENTITY'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
        },
        {
          name: 'startDate',
          type: 'date',
          placeholder: 'Start Date',
        },
        {
          name: 'endDate',
          type: 'date',
          placeholder: 'End Date',
        },
        {
          name: 'search',
          placeholder: 'Keyword...',
        },
        {
          name: 'stageLoadId',
          placeholder: 'File Load ID',
        }
      ],
      searchCallback: () => { this.initNewSearch(); },
    };
    this.title.setTitle('Processing Logs');
    this.initFilter();
  }

  initNewSearch(): void {
    this.initFilter();
    this.getProcessingLogs(false);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  getProcessingLogs(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.searchFilters.getFilters(this.filter);
    this.processingActivityLogService.getProcessingLogs(this.utilityService.removeNullAndEmptyProperties(this.filter))
      .subscribe((data: any) => {
        if (concat) {
          this.processingLogs = this.processingLogs.concat(parseResponse(data));
        } else {
          this.processingLogs = parseResponse(data);
        }
        this.lengthToCompare = parseResponse(data).length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = parseResponse(data).length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      }, () => {
        this.toastr.error('Error occured');
        this.lockedTables = false;
        this.lock = false;
        this.isLoading = false;
      });
  }

  setStatuses(): void {
    this.initFilter();
    this.filter.statuses = this.tableHelperService.extractData(this.filteredStatuses);
    this.getProcessingLogs(false);
  }
}
