<form [formGroup]="commAttrForm">
  <div class="modal-header">
    <h1>{{title}}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{errorMsg}}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <label>Name</label>
      <input type="text" class="form-control" formControlName="attrName">
      <div class="mt-0" [hidden]="commAttrForm.controls['attrName'].valid || attr?.attrName">
        <small class="text-danger">Please input attribute name</small>
      </div>
    </div>
    <div class="form-group">
      <label>Value</label>
      <input type="text" class="form-control" formControlName="attrValue">
      <div class="mt-0" [hidden]="commAttrForm.controls['attrValue'].valid">
        <small class="text-danger">Please input value</small>
      </div>
    </div>
    <div class="form-group">
      <small><em>Note: All times are UTC</em></small>
    </div>
    <div class="form-group" *ngIf="resourceType === 'party'">
      <label>Effective Date</label>
      <div class="input-group date">
        <input class="form-control cursor-pointer" readonly placeholder="yyyy-mm-dd" name="effectiveFrom" ngbDatepicker
          #effectiveFrom="ngbDatepicker" formControlName="effectiveFrom"
          (click)="effectiveFrom.toggle()" [footerTemplate]="footerTemplateEffectiveFrom">
        <ng-template #footerTemplateEffectiveFrom>
          <div class="width-100 datePickerTemplate">
            <button class="buttonDateTemplate"
              (click)="clearDate('effectiveFrom'); effectiveFrom.toggle()">Clear</button>
          </div>
        </ng-template>
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="effectiveFrom.toggle()" type="button">
            <fa-icon [icon]="faCalendar"></fa-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="resourceType === 'party'">
      <label>Expiration Date</label>
      <div class="input-group date">
        <input class="form-control cursor-pointer" readonly (click)="effectiveTo.toggle()" placeholder="yyyy-mm-dd"
          name="effectiveTo" ngbDatepicker #effectiveTo="ngbDatepicker" formControlName="effectiveTo"
          [footerTemplate]="footerTemplateEffectiveTo">
        <ng-template #footerTemplateEffectiveTo>
          <div class="width-100 datePickerTemplate">
            <button class="buttonDateTemplate"
              (click)="clearDate('effectiveTo'); effectiveTo.toggle()">Clear</button>
          </div>
        </ng-template>

        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="effectiveTo.toggle()" type="button">
            <fa-icon [icon]="faCalendar"></fa-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="resourceType === 'promotion'">
      <label>Display Name</label>
      <input type="text" class="form-control" formControlName="displayName">
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark-blue" type="submit" [disabled]="!commAttrForm.valid || !commAttrForm.dirty" (click)="ok()" title="Save">
      <fa-icon [icon]="faSave"></fa-icon>
    </button>
    <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
</form>
