<div>
  <form>
    <label>The previous amount Rewarded must be:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="tenderAmountThreshold !== null">
        <div *ngFor="let tenAmtThreshold of tenderAmountThreshold; let i=index" class="border-card-padding form-group col-lg-12">
          <button class="btn btn-attention float-right" type="button" (click)="removeThreshold(rewCntThreshold)" title="Remove Threshold">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label class="control-label" for="thresholdVal_{{tenAmtThreshold.thresholdValue}}">at least (greater than or equal to):</label>
              <input class="form-control m-b" type="number" id="thresholdVal_{{tenAmtThreshold.thresholdValue}}" name="thresholdValue" [(ngModel)]="tenAmtThreshold.thresholdValue" (ngModelChange)="updateThresholds()" />
            </div>
            <div class="form-group">
              <a class="control-label" ng-class="{'disabled-link': readOnly}"
                 (click)="regexSearchModal('BEHAVIORS', { object: tenAmtThreshold, name: 'behaviorCodes' }, {windowClass: 'wider-modal-window'}, false, false, i)"
                 title="Search for Behaviors">on Behaviors with Codes (leave blank for any):</a>  <input class="form-control m-b" type="text" id="behCodes_{{tenAmtThreshold.thresholdValue}}" name="behaviorCodes" [(ngModel)]="tenAmtThreshold.behaviorCodes" (ngModelChange)="updateThresholds()" />
            </div>
            <div class="form-group">
              <label>for the current:</label>
              <select name="thresholdPeriod_{{tenAmtThreshold.thresholdValue}}" class="form-control"
                      [(ngModel)]="tenAmtThreshold.thresholdPeriod && tenAmtThreshold.thresholdPeriod.value"
                      (ngModelChange)="!tenAmtThreshold.thresholdPeriod ? tenAmtThreshold['thresholdPeriod'] = { value: $event, class: 'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Schedules$Thresholds$TenderAmountThreshold$ThresholdPeriod'} : null; updateThresholds()">
              <option *ngFor="let period of periods" [value]="period.value">{{period.text}}</option>
              </select>
            </div>
            <div class="form-group">
              <label>Should the total include activities from later in the period than the current activity?</label>
              <select name="inclusion_{{tenAmtThreshold.thresholdValue}}" class="form-control"
                      [(ngModel)]="tenAmtThreshold.thresholdPeriod && tenAmtThreshold.thresholdPeriod.inclusion"
                      (ngModelChange)="!tenAmtThreshold.thresholdPeriod ? tenAmtThreshold['thresholdPeriod'] = { inclusion: $event, class: 'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Schedules$Thresholds$TenderAmountThreshold$ThresholdPeriod'} : null; updateThresholds()">
              <option *ngFor="let inclusionVal of inclusionValues" [value]="inclusionVal.value">{{inclusionVal.text}}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label" for="previousPeriodCount_{{tenAmtThreshold.thresholdValue}}">How many previous periods should be included in the time span (leave empty for only the current period):</label>
              <input class="form-control m-b" type="number" id="previousPeriodCount_{{tenAmtThreshold.thresholdValue}}" name="previousPeriodCount" [(ngModel)]="tenAmtThreshold.previousPeriodCount" (ngModelChange)="updateThresholds()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="relationshipName_{{tenAmtThreshold.thresholdValue}}">Include amounts from members related by (leave blank for current member only or use a comma separated list):</label>
              <input class="form-control m-b" type="text" id="relationshipName_{{tenAmtThreshold.thresholdValue}}" name="relationshipName" [(ngModel)]="tenAmtThreshold.relationshipName" (ngModelChange)="updateThresholds()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="friendlyMessage_{{tenAmtThreshold.thresholdValue}}">Message:</label>
              <input class="form-control m-b" type="text" id="friendlyMessage_{{tenAmtThreshold.thresholdValue}}" name="friendlyMessage" [(ngModel)]="tenAmtThreshold.friendlyMessage" (ngModelChange)="updateThresholds()" />
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initThresholdObject()"><fa-icon [icon]="faPlus"></fa-icon> Add Tender Amount Threshold</button>
      </div>
    </div>
  </form>
</div>
