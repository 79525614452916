<form>
  <div class="modal-header">
    <h1>{{title}}</h1>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <div class="row">
        <div class="col-md-4 pull-left">
          <label>Search for:</label>
        </div>
        <div class="col-md-6 pull-left">
          <label>Regex:</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 pull-left">
          <select [(ngModel)]="select.selectedSearch" name="searchFor" class="form-control" [disabled]="disableSearchType" required>
            <option value=""></option>
            <option value="PROMOTIONS">Promotions</option>
            <option value="BEHAVIORS">Behaviors</option>
            <option value="LOCATIONS">Locations</option>
            <option value="INSTRUMENTTYPES">Instrument Types</option>
            <option value="COMMUNICATIONS">Communications</option>
          </select>
        </div>
        <div class="col-md-7 pull-left">
          <input type="text" name="searchPattern" [(ngModel)]="searchPattern" class="form-control" value="{{searchFor}}" />

        </div>
        <div class="col-md-1 pull-right">
          <button class="btn btn-dark-blue pull-right" type="submit" (click)="doRegexSearch()" [disabled]="!select.selectedSearch"><fa-icon [icon]="faSearch"></fa-icon></button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-11">
          <label>Result:</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-11">
          <textarea type="text" style="height: 75px; min-height: 75px;" name=result [(ngModel)]="result" class="form-control vertical-expand" value="{{result}}" [disabled]="true"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5 pull-right">
          <a class="pull-right clear-filter" (click)="clearAll()">Clear all</a>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body" style="max-height: 400px; overflow-y: auto; padding-top: 20px">
    <div class="row table-results" ng-show="searchResults.length > 0" style="max-height: 60vh">
      <table class="table table-pointable table-bordered infinite-table">
        <thead>
        <tr>
          <th style="width: 55px">
            <div class="checkbox checkbox-info pull-left m-t-none m-b-none" style="display: inline-block;width: 50%;">
              <input id="select-all-cb" ng-model="checkAll" type="checkbox" class="form-control" (click)="selectAllCb($event)" [disabled]="limitToOne">
              <label for="select-all-cb"></label>
            </div>
          </th>
          <th *ngIf="select.selectedSearch !== 'COMMUNICATIONS'">Code</th>
          <th>Name</th>
          <th *ngIf="select.selectedSearch !== 'COMMUNICATIONS'">Description</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let result of searchResults">
          <td>
            <div class="checkbox checkbox-info m-t-none m-b-none pull-left" style="display: inline-block;width: 50%;">
              <input id="result.code ? cb-{{result.code}} : cb-{{result.name}}" class="proc-check" type="checkbox" (click)="updateSelectedCodes(result.code ? result.code : result.name)" />
              <label *ngIf="select.selectedSearch !== 'COMMUNICATIONS'" for="cb-{{result.code}}"></label>
              <label *ngIf="select.selectedSearch === 'COMMUNICATIONS'" for="cb-{{result.name}}"></label>
            </div>
          </td>
          <td *ngIf="select.selectedSearch !== 'COMMUNICATIONS'">{{result.code}}</td>
          <td>{{result.name}}</td>
          <td *ngIf="select.selectedSearch !== 'COMMUNICATIONS'">{{result.description}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
          <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <button class="btn btn-attention" type="button" (click)="insertCodes()" title="Insert"><fa-icon [icon]="faCheck"></fa-icon>
            <!--copy-to-clipboard="{{result}}"-->

    </button>
  </div>

</form>
