<form [formGroup]="deliveryProtocolForm">
  <div class="modal-header">
    <h1>{{title}}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{errorMsg}}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Protocol Name</label>
          <input type="text" formControlName="name" class="form-control">
          <div class="mt-sm" [hidden]="deliveryProtocolForm.controls['name'].valid">
            <small class="text-danger">Input protocol Name</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Endpoint Type</label>
          <ng-select [items]="endpointTypes" bindLabel="name"  formControlName="endpointType" [hideSelected]="true"
            [closeOnSelect]="false">
          </ng-select>
          <div class="mt-xs" [hidden]="deliveryProtocolForm.controls.endpointType.valid">
            <small class="text-danger">Select type</small>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark-blue" type="submit" (click)="ok()" [disabled]="!deliveryProtocolForm.valid" title="Save">
      <fa-icon [icon]="faSave"></fa-icon>
    </button>
    <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
</form>