<form [formGroup]="rewardTypeForm">
  <div class="modal-header">
    <h1>{{title}}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{errorMsg}}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Name</label>
          <input type="text" class="form-control" formControlName="name">
          <div class="mt-sm" [hidden]="rewardTypeForm.controls['name'].valid">
            <small class="text-danger">Input name</small>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-group">
          <label>Code</label>
          <input type="text" class="form-control" formControlName="code">
          <div class="mt-sm" [hidden]="rewardTypeForm.controls['code'].valid">
            <small class="text-danger">Input code</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Status</label>
          <select formControlName="status" class="form-control">
            <option *ngFor="let option of statuses" value="{{option}}">{{option}}</option>
          </select>
          <div class="mt-sm" [hidden]="rewardTypeForm.controls['status'].valid">
            <small class="text-danger">Select status</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>World Currency</label>
          <select formControlName="currencyId" class="form-control">
            <option *ngFor="let option of currencies" value="{{option.id}}">{{option.name }}</option>
          </select>
          <div class="mt-sm" [hidden]="rewardTypeForm.controls['currencyId'].valid">
            <small class="text-danger">Select world currency</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Description</label>
          <textarea formControlName="description" class="form-control vertical-expand"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="checkbox checkbox-success">
          <input formControlName="isExpiring" id="isExpiring" class="mr-2" type="checkbox">
          <label for="isExpiring">
            Expiring
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Expiry Months</label>
          <input type="number" min="0" class="form-control" formControlName="expiryMonths">
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark-blue" type="submit" [disabled]="!rewardTypeForm.valid" (click)="ok()" title="Save"><fa-icon [icon]="faSave"></fa-icon></button>
    <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel"><fa-icon [icon]="faTimes"></fa-icon></button>
  </div>
</form>