import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { InstrumentType, ActivityInstrument } from '../../../data/model';
import { StatusHelperService } from '../../../services/helpers/status-helper.service';
import { faCalendar, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DateHelperService } from '../../../services/helpers/date-helper.service';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InstrumentsService } from '../../../services/instruments.service';
import { ResponseHelperService } from '../../../services/helpers/response-helper.service';

@Component({
  selector: 'app-instrument-modal',
  templateUrl: './instrument-modal.component.html',
  styleUrls: ['./instrument-modal.component.scss']
})
export class InstrumentModalComponent implements OnInit {

  @Input() instrument: ActivityInstrument;
  @Input() instruments: ActivityInstrument[];
  @Input() instrumentTypes: InstrumentType[];
  @Input() personId: number;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  selectedType: InstrumentType;
  title: string;
  errorMsg = '';
  activationDateValue: NgbDate;
  expiryDateValue: NgbDate;
  cancelationDateValue: NgbDate;
  closeDateValue: NgbDate;

  // TODO:
  instrumentTypeCode: string;

  faCalendar = faCalendar;
  faSave = faSave;
  faTimes = faTimes;

  constructor(
    private statusHelperService: StatusHelperService,
    private dateHelperService: DateHelperService,
    private modalHelper: NgbModal,
    private instrumentsService: InstrumentsService,
    private responseHelperService: ResponseHelperService
  ) { }

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    if (this.instrument) {
      this.title = 'Edit Instrument';
      this.instrumentTypeCode = this.instrument.instrumentType.code;
    } else {
      this.title = 'New Instrument';
      this.instrument = new ActivityInstrument();
    }
  }

  clearDateInput(name: string): void {
    this[name] = null;
  }


  formatDate(name: string): void {
    switch (name) {
      case 'activationDate':
        if (this.activationDateValue !== null) {
          this.instrument.activationDate = this.dateHelperService.ngbDateToString(this.activationDateValue);
        } else {
          this.instrument.activationDate = null;
        }
        break;
      case 'expiryDate':
        if (this.expiryDateValue !== null) {
          this.instrument.expiryDate = this.dateHelperService.ngbDateToString(this.expiryDateValue);
        } else {
          this.instrument.expiryDate = null;
        }
        break;
      case 'cancelationDate':
        if (this.cancelationDateValue !== null) {
          this.instrument.cancelationDate = this.dateHelperService.ngbDateToString(this.cancelationDateValue);
        } else {
          this.instrument.cancelationDate = null;
        }
        break;
      case 'closeDate':
        if (this.closeDateValue !== null) {
          this.instrument.closeDate = this.dateHelperService.ngbDateToString(this.closeDateValue);
        } else {
          this.instrument.closeDate = null;
        }
        break;
      default:
    }
  }

  ok(): void {
    this.instrument.partyId = this.personId;
    if (this.title === "New Instrument" && this.instruments.length === 0) {
      this.instrument.isPrimary = true;
    }
    this.instrumentsService.createOdUpdateInstrument(this.instrument.id, this.instrument).subscribe((data: any) => {
      if (data.success) {
        this.responseHelperService.success('Instrument successfully updated', true);
        this.successEvent.emit();
      }
    });
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

  setInstrumentType(code: string): void {
    for (let type of this.instrumentTypes) {
      if (code === type.code) {
        this.instrument.instrumentType = type;
      }
    }
  }
}
