<div class="" full-scroll *ngIf="person && oldMemberDetailCode">
  <app-search-filter [searchFilters]="searchFilters" (notifyParent)="getNotification($event)"></app-search-filter>
  <div>
    <div style="min-height: 800px; border: #ccc 1px solid; margin-top: 1em; padding-top: 1em;">
      <div class="row">
        <div class="col-md-3">
          <p class="user-name"
             style="margin-left:2%">{{person.firstName}}&nbsp;{{person.middleInitial}}&nbsp;{{person.lastName}}</p>
          <p class="id-number" style="margin-left:2%">ID&nbsp;{{primaryInstrumentCode}}</p>
        </div>
        <div class="col-md-9">
          <app-campaign-info-panel [campaigns]="oldMemberDetailCode.campaigns"></app-campaign-info-panel>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <app-eligible-promotions-panel *ngIf="promotionPodType === 'standard'"
                                         [personId]="personId"
                                         [promoGroups]="oldMemberDetailCode.promoGroups"
                                         [activeCategory]="oldMemberDetailCode.activeCategory"
                                         [promoCategories]="oldMemberDetailCode.promoCategories"
                                         [isProgramManager]="isProgramManager">
          </app-eligible-promotions-panel>

          <app-anthem-eligible-promotions-panel *ngIf="promotionPodType === 'anthem-commercial'"
                                                [personId]="personId"
                                                [promoGroups]="oldMemberDetailCode.promoGroups"
                                                [activeCategory]="oldMemberDetailCode.activeCategory"
                                                [promoCategories]="oldMemberDetailCode.promoCategories">
          </app-anthem-eligible-promotions-panel>
        </div>
      </div>
    </div>
  </div>
</div>
