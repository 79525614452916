<div>
  <form>
    <label>The number of rewards:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="attrIntervalCountCap !== null">
        <div *ngFor="let attrIntCntCap of attrIntervalCountCap; let i=index" class="border-card-padding form-group col-lg-12">
          <button class="btn btn-attention float-right" type="button" (click)="removeCap(cntCap)" title="Remove Cap">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label class="control-label" for="attrName_{{attrIntCntCap.capValue}}">For the effective date range (covering the activity date) of the attribute:</label>
              <input class="form-control m-b" type="text" id="attrName_{{attrIntCntCap.capValue}}" name="attrName" [(ngModel)]="attrIntCntCap.attrName" (ngModelChange)="updateCaps()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="attrValue_{{attrIntCntCap.capValue}}">with value (leave blank for any):</label>
              <input class="form-control m-b" type="text" id="attrValue_{{attrIntCntCap.capValue}}" name="attrValue" [(ngModel)]="attrIntCntCap.attrValue" (ngModelChange)="updateCaps()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="capValue_{{attrIntCntCap.capValue}}">caps at (maximum number):</label>
              <input class="form-control m-b" type="number" id="capValue_{{attrIntCntCap.capValue}}" name="capValue" [(ngModel)]="attrIntCntCap.capValue" (ngModelChange)="updateCaps()" />
            </div>
            <div class="form-group">
              <a class="control-label" ng-class="{'disabled-link': readOnly}"
                 (click)="regexSearchModal('PROMOTIONS', { object: attrIntCntCap, name: 'promotionCodes' }, {windowClass: 'wider-modal-window'}, false, false, i)"
                 title="Search for Promotions">across Promotions (leave blank for the current promotion or use a comma separated list):</a>  <input class="form-control m-b" type="text" id="promoCodes_{{attrIntCntCap.capValue}}" name="promotionCodes" [(ngModel)]="attrIntCntCap.promotionCodes" (ngModelChange)="updateCaps()" />
            </div>
            <div class="form-group">
              <a class="control-label" ng-class="{'disabled-link': readOnly}"
                 (click)="regexSearchModal('BEHAVIORS', { object: attrIntCntCap, name: 'behaviorCodes' }, {windowClass: 'wider-modal-window'}, false, false, i)"
                 title="Search for Behaviors">limited to Behaviors with Codes (leave blank for any or use a comma separated list):</a>  <input class="form-control m-b" type="text" id="behaviorCodes_{{attrIntCntCap.capValue}}" name="behaviorCodes" [(ngModel)]="attrIntCntCap.behaviorCodes" (ngModelChange)="updateCaps()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="relationshipName_{{attrIntCntCap.capValue}}">Include counts from members related by (leave blank for current member only or use a comma separated list):</label>
              <input class="form-control m-b" type="text" id="relationshipName_{{attrIntCntCap.capValue}}" name="relationshipName" [(ngModel)]="attrIntCntCap.relationshipName" (ngModelChange)="updateCaps()" />
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initCapObject()"><fa-icon [icon]="faPlus"></fa-icon> Add Attribute Interval Count Cap</button>
      </div>
    </div>
  </form>
</div>
