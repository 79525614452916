<div>
  <form>
    <label>Gatekeepers for this promotion:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="gatekeepers && gatekeepers.gatekeeper">
        <div *ngFor="let gtKeeper of gatekeepers.gatekeeper; let i=index" class="border-card-padding form-group col-lg-12">
          <button class="btn btn-attention float-right" type="button" (click)="removeThreshold(rewCntThreshold)" title="Remove Threshold">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <a class="control-label" ng-class="{'disabled-link': readOnly}"
                 (click)="regexSearchModal('PROMOTIONS', { object: gtKeeper, name: 'value' }, {windowClass: 'wider-modal-window'}, true, false, i)"
                 title="Search for Promotions">gatekeeper promotion code:</a>  <input class="form-control m-b" type="text" id="behCodes_{{gtKeeper.value}}" name="value" [(ngModel)]="gtKeeper.value" (ngModelChange)="updateThresholds()" />
            </div>
            <div class="form-group">
              <label>for the current:</label>
              <select name="thresholdPeriod_{{gtKeeper.value}}" class="form-control" [(ngModel)]="gtKeeper.period" (ngModelChange)="updateThresholds()">
                <option *ngFor="let period of periods" [selected]="gtKeeper.period === period.value" [value]="period.value">{{period.text}}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label" for="previousPeriodCount_{{gtKeeper.value}}">minimum number of gatekeeper behaviors:</label>
              <input class="form-control m-b" type="number" id="previousPeriodCount_{{gtKeeper.value}}" name="previousPeriodCount" [(ngModel)]="gtKeeper.min" (ngModelChange)="updateThresholds()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="relationshipName_{{gtKeeper.value}}">number of days to look back for transfer (leave blank for entire period):</label>
              <input class="form-control m-b" type="number" id="relationshipName_{{gtKeeper.value}}" name="relationshipName" [(ngModel)]="gtKeeper.xferLimit" (ngModelChange)="updateThresholds()" />
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initThresholdObject()"><fa-icon [icon]="faPlus"></fa-icon>  Add Gatekeeper</button>
      </div>
    </div>
  </form>
</div>
