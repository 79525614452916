<div>
  <form>
    <label>The number of rewards per matching behavior code:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="perBehaviorCountCap !== null">
        <div *ngFor="let perBehCntCap of perBehaviorCountCap; let i=index" class="border-card-padding form-group col-lg-12">
          <button class="btn btn-attention float-right" type="button" (click)="removeCap(cntCap)" title="Remove Cap">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label>for the current:</label>
              <select name="period_{{perBehCntCap.capValue}}" class="form-control" [(ngModel)]="perBehCntCap.capPeriod" (ngModelChange)="updateCaps()">
                <option *ngFor="let period of periods" [selected]="perBehCntCap.capPeriod === period.value" [value]="period.value">{{period.text}}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label" for="capValue_{{perBehCntCap.capValue}}">caps at (maximum number):</label>
              <input class="form-control m-b" type="number" id="capValue_{{perBehCntCap.capValue}}" name="capValue" [(ngModel)]="perBehCntCap.capValue" (ngModelChange)="updateCaps()" />
            </div>
            <div class="form-group">
              <a class="control-label" ng-class="{'disabled-link': readOnly}"
                 (click)="regexSearchModal('PROMOTIONS', { object: perBehCntCap, name: 'promotionCodes' }, {windowClass: 'wider-modal-window'}, false, false, i)"
                 title="Search for Promotions">across Promotions (leave blank for the current promotion or use a comma separated list):</a>  <input class="form-control m-b" type="text" id="promoCodes_{{perBehCntCap.capValue}}" name="behaviorCodes" [(ngModel)]="perBehCntCap.promotionCodes" (ngModelChange)="updateCaps()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="previousPeriodCount_{{perBehCntCap.capValue}}">How many previous periods should be included in the time span (leave empty for only the current period):</label>
              <input class="form-control m-b" type="number" id="previousPeriodCount_{{perBehCntCap.capValue}}" name="previousPeriodCount" [(ngModel)]="perBehCntCap.previousPeriodCount" (ngModelChange)="updateCaps()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="relationshipName_{{perBehCntCap.capValue}}">Include counts from members related by (leave blank for current member only or use a comma separated list):</label>
              <input class="form-control m-b" type="text" id="relationshipName_{{perBehCntCap.capValue}}" name="relationshipName" [(ngModel)]="perBehCntCap.relationshipName" (ngModelChange)="updateCaps()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="friendlyMessage_{{perBehCntCap.capValue}}">Message:</label>
              <input class="form-control m-b" type="text" id="friendlyMessage_{{perBehCntCap.capValue}}" name="friendlyMessage" [(ngModel)]="perBehCntCap.friendlyMessage" (ngModelChange)="updateCaps()" />
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initCapObject()"><fa-icon [icon]="faPlus"></fa-icon> Add Per Behavior Reward Count Cap</button>
      </div>
    </div>
  </form>
</div>
