<div class="wrapper wrapper-content">
  <div class="ibox">
    <div class="ibox-content">
      <div class="display-title">
          <h2>Promotion Display Template Editor</h2>
        </div>
        <div class="ibox-content">
<div class="panel-body">
  <div class="row">
    <div class="col-md-12" *ngIf="show">
      <div class="wrapper wrapper-content">
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-lg-12">
                <div class="ibox">
                  <div class="display-title border-bottom" (click)="resizeIbox($event, 1)">
                    <h4 class="user-select-none">General</h4>
                    <div class="ibox-tools">
                      <a>
                        <fa-icon
                        [icon]="expandedLookup[1] ? faChevronUp : faChevronDown">
                      </fa-icon>
                      </a>
                    </div>
                  </div>
                  <form #f="ngForm" name="form">
                    <div class="ibox-content" [@expandCollapse] = "expandedLookup[1] ? 'open': 'close'" >
                      <div class="form-group row" *ngIf="selectedTemplate">
                        <label class="col-sm-4 control-label">Promotion Template Name</label>
                        <div class="col-sm-8">
                          <input name="selectedTemplateName" type="text" class="form-control"
                            [(ngModel)]="selectedTemplate.name">
                        </div>
                      </div>
                      <div class="form-group row" *ngIf="selectedTemplate">
                        <label class="col-sm-4 control-label">Promotion Template Description</label>
                        <div class="col-sm-8">
                          <input name="selectedTemplateDescription" type="text" class="form-control"
                            [(ngModel)]="selectedTemplate.description">
                        </div>
                      </div>
                      <div class="form-group row" *ngIf="selectedTemplate">
                        <label class="col-sm-4 control-label">Program Package</label>
                        <div class="col-sm-8">
                          <select name="selectedTemplatePackageId" [(ngModel)]="selectedTemplate.packageId"
                            class="form-control">
                            <option *ngFor="let package of programPackages" value="{{package.id}}">
                              {{package.packageName}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Promotion Title</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" name="title" [(ngModel)]="model.title">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Promotion Subtitle</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" name="subtitle" [(ngModel)]="model.subtitle">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Promotion Image URL</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" name="imageUrl" [(ngModel)]="model.imageUrl">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Reward Display Amount</label>
                        <div class="col-sm-8">
                          <input type="number" class="form-control" name="displayAmount"
                            [(ngModel)]="model.displayAmount">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Reward Display Currency</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" name="displayCurrency"
                            [(ngModel)]="model.displayCurrency">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Reward Image URL</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" name="displayRewardImageUrl"
                            [(ngModel)]="model.displayRewardImageUrl">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Button Text</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" name="buttonText" [(ngModel)]="model.buttonText">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Button URL</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" name="buttonUrl" [(ngModel)]="model.buttonUrl" [disabled]="disableButtonUrl">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Custom URL Attribute Name</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" name="customUrlAttribute" [(ngModel)]="customUrlAttribute" (change)="customUrlAttributeChange(customUrlAttribute)">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Video HTML</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" name="videoHTML" [(ngModel)]="model.videoHTML">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Display Order</label>
                        <div class="col-sm-8">
                          <input type="number" class="form-control" name="displayOrder"
                            [(ngModel)]="model.displayOrder">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Threshold Group</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" name="trasholdGroup"
                            [(ngModel)]="model.thresholdGroup">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Threshold Group Min</label>
                        <div class="col-sm-8">
                          <input number-limit="9" type="number" class="form-control" name="trasholdGroupMin"
                            [(ngModel)]="model.thresholdGroupMin">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Cap Group</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" name="capGroup" [(ngModel)]="model.capGroup">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Display NEW Flag On</label>
                        <div class="input-group date col-sm-8">
                          <input class="form-control cursor-pointer" (click)="displayNewFlagOn.toggle()"
                            placeholder="yyyy-mm-dd" name="displayNewFlagOn" ngbDatepicker
                            #displayNewFlagOn="ngbDatepicker" [(ngModel)]="model.displayNewFlagOn"
                            [footerTemplate]="footerTemplateDisplayNewFlagOn">
                          <ng-template #footerTemplateDisplayNewFlagOn>
                            <div class="width-100 datePickerTemplate">
                              <button class="buttonDateTemplate"
                                (click)="clearDate('displayNewFlagOn'); displayNewFlagOn.toggle()">Clear</button>
                            </div>
                          </ng-template>
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="displayNewFlagOn.toggle()"
                              type="button">
                              <fa-icon [icon]="faCalendar"></fa-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Hide NEW Flag On</label>
                        <div class="input-group date col-sm-8">
                          <input class="form-control cursor-pointer" (click)="displayNewFlagUntil.toggle()"
                            placeholder="yyyy-mm-dd" name="displayNewFlagUntil" ngbDatepicker
                            #displayNewFlagUntil="ngbDatepicker" [(ngModel)]="model.displayNewFlagUntil"
                            [footerTemplate]="footerTemplateDisplayNewFlagUntil">
                          <ng-template #footerTemplateDisplayNewFlagUntil>
                            <div class="width-100 datePickerTemplate">
                              <button class="buttonDateTemplate"
                                (click)="clearDate('displayNewFlagUntil'); displayNewFlagUntil.toggle()">Clear</button>
                            </div>
                          </ng-template>
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="displayNewFlagUntil.toggle()"
                              type="button">
                              <fa-icon [icon]="faCalendar"></fa-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Display EXPIRE Flag On</label>
                        <div class="input-group date col-sm-8">
                          <input class="form-control cursor-pointer" (click)="displayExpireFlagOn.toggle()"
                            placeholder="yyyy-mm-dd" name="displayExpireFlagOn" ngbDatepicker
                            #displayExpireFlagOn="ngbDatepicker" [(ngModel)]="model.displayExpireFlagOn"
                            [footerTemplate]="footerTemplateDisplayExpireFlagOn">
                          <ng-template #footerTemplateDisplayExpireFlagOn>
                            <div class="width-100 datePickerTemplate">
                              <button class="buttonDateTemplate"
                                (click)="clearDate('displayExpireFlagOn'); displayExpireFlagOn.toggle()">Clear</button>
                            </div>
                          </ng-template>
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="displayExpireFlagOn.toggle()"
                              type="button">
                              <fa-icon [icon]="faCalendar"></fa-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Hide EXPIRE Flag On</label>
                        <div class="input-group date col-sm-8">
                          <input class="form-control cursor-pointer" (click)="displayExpireFlagUntil.toggle()"
                            placeholder="yyyy-mm-dd" name="displayExpireFlagUntil" ngbDatepicker
                            #displayExpireFlagUntil="ngbDatepicker" [(ngModel)]="model.displayExpireFlagUntil"
                            [footerTemplate]="footerTemplateDisplayExpireFlagUntil">
                          <ng-template #footerTemplateDisplayExpireFlagUntil>
                            <div class="width-100 datePickerTemplate">
                              <button class="buttonDateTemplate"
                                (click)="clearDate('displayExpireFlagUntil'); displayExpireFlagUntil.toggle()">Clear</button>
                            </div>
                          </ng-template>
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="displayExpireFlagUntil.toggle()"
                              type="button">
                              <fa-icon [icon]="faCalendar"></fa-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Description</label>
                        <div class="col-sm-8">
                          <angular-editor name="description" [(ngModel)]="model.description"></angular-editor>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Disclaimer</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" name="disclaimer" [(ngModel)]="model.disclaimer">
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Gatekeeper</label>
                        <div class="col-sm-8">
                          <input class="mt-2" type="checkbox" [(ngModel)]="model.isGatekeeper" name="isGatekeeper"
                            id="isGatekeeper" />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="ibox">
                  <div class="display-title border-bottom" (click)="resizeIbox($event,2)">
                    <h4 class="user-select-none">Alternatives</h4>
                    <div class="ibox-tools">
                      <a>
                        <fa-icon
                        [icon]="expandedLookup[2] ? faChevronUp : faChevronDown">
                      </fa-icon>
                      </a>
                    </div>
                  </div>
                  <form #f="ngForm" name="form" *ngIf="model.alternativesConfiguration">
                    <div class="ibox-content" [@expandCollapse] = "expandedLookup[2] ? 'open': 'close'" >
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Display Link</label>
                        <div class="col-sm-8">
                          <input class="mt-2" type="checkbox" [(ngModel)]="model.alternativesConfiguration.displayLink"
                            name="displayLink" />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Link Label</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" name="linkLabel"
                            [(ngModel)]="model.alternativesConfiguration.linkLabel">
                        </div>
                      </div>
                      <div class="form-group row" *ngIf="model.alternativesConfiguration?.toolTip">
                        <label class="col-sm-4 control-label">Tooltip</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" name="toolTip"
                            [(ngModel)]="model.alternativesConfiguration.toolTip">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Submission Behavior Code</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" name="submissionBehaviorCode"
                            [(ngModel)]="model.alternativesConfiguration.submissionBehaviorCode">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Submission Attribute Name</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" name="submissionAttrName"
                            [(ngModel)]="model.alternativesConfiguration.submissionAttrName">
                        </div>
                      </div>
                      <div class="form-group">
                        <ngb-accordion #acc="ngbAccordion" [closeOthers]="true"
                          *ngIf="model.alternativesConfiguration?.alternatives">
                          <ngb-panel
                            *ngFor="let alternative of model.alternativesConfiguration.alternatives.alternative; let i = index">
                            <ng-template ngbPanelHeader>
                              Alternative {{alternative.promotionName? '- '+ alternative.promotionName:''}}
                              <button ngbPanelToggle class="btn btn-dark-blue float-right" (click)="editAlternative(i)"
                                title="Edit">
                                <fa-icon
                                  [icon]="i === openFieldChevronList.alternativeIndex ? faChevronUp : faChevronDown">
                                </fa-icon>
                              </button>
                              <button class="btn btn-attention float-right" (click)="removeAlternative($index)"
                                title="Remove">
                                <fa-icon [icon]="faTrash"></fa-icon>
                              </button>
                            </ng-template>
                            <ng-template ngbPanelContent>
                              <h2>
                                {{alternative.promotionCode}} - {{alternative.promotionName}}
                              </h2>
                              <app-alternative-form [index]="i"
                                [alternativeFormModel]="model.alternativesConfiguration.alternatives.alternative[i]"
                                (alternativeEvent)="handleAlternativeEvent($event)"></app-alternative-form>
                            </ng-template>
                          </ngb-panel>
                        </ngb-accordion>
                        <h2>New Alternative</h2>
                        <app-alternative-form [index]="null" (alternativeEvent)="handleAlternativeEvent($event)">
                        </app-alternative-form>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="ibox">
                  <div class="display-title border-bottom" (click)="resizeIbox($event,3)">
                    <h4 class="user-select-none">Completion Indicator</h4>
                    <div class="ibox-tools">
                      <a>
                        <fa-icon
                        [icon]="expandedLookup[3] ? faChevronUp : faChevronDown">
                      </fa-icon>
                      </a>
                    </div>
                  </div>
                  <form #f="ngForm" name="form">
                    <div class="ibox-content" [@expandCollapse] = "expandedLookup[3] ? 'open': 'close'" >
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Completion Indicator</label>
                        <div class="col-sm-8">
                          <input type="checkbox" name="completionIndicatorSelected"
                            [(ngModel)]="completionIndicatorSelected" (ngModelChange)="updateCompletionIndicator()"/>
                        </div>
                      </div>
                      <div class="form-group row"
                        *ngIf="completionIndicatorSelected && !parentId && (childCompletionPromoCodes?.length > 0 || childPromoCodes?.length > 0)">
                        <label class="col-sm-4 control-label">Calculate based on child promotions</label>
                        <div class="col-sm-8">
                          <div class="checkbox checkbox-info">
                            <input name="useChildren" type="checkbox"
                              [(ngModel)]="model.completionIndicator.useChildren">
                          </div>
                        </div>
                      </div>
                      <div class="form-group row" *ngIf="completionIndicatorSelected && !model.completionIndicator?.useChildren">
                        <label class="col-sm-4 control-label">Progress Type</label>
                        <div class="col-sm-8">
                          <select [(ngModel)]="model.completionIndicator.progressType" name="progressType"
                            class="form-control">
                            <option *ngFor="let type of progressType" value="{{type.code}}">
                              {{type.text}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="form-group row" *ngIf="completionIndicatorSelected">
                        <label class="col-sm-4 control-label">Min Count</label>
                        <div class="col-sm-8">
                          <input type="number" class="form-control" name="minCount"
                            [(ngModel)]="model.completionIndicator.minCount">
                        </div>
                      </div>
                      <div class="form-group row" *ngIf="completionIndicatorSelected">
                        <label class="col-sm-4 control-label">Max Count</label>
                        <div class="col-sm-8">
                          <input type="number" class="form-control" name="maxCount"
                            [(ngModel)]="model.completionIndicator.maxCount">
                        </div>
                      </div>
                      <div class="form-group row" *ngIf="completionIndicatorSelected && !model.completionIndicator?.useChildren">
                        <label class="col-sm-4 control-label">Period</label>
                        <div class="col-sm-8">
                          <select [(ngModel)]="model.completionIndicator.period" name="period" class="form-control">
                            <option *ngFor="let period of periodValues" value="{{period.text}}">
                              {{period.text}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="form-group row" *ngIf="completionIndicatorSelected && model.completionIndicator?.period === 'CUSTOM'">
                        <label class="col-sm-4 control-label">From Date</label>
                        <div class="input-group date col-sm-8">
                          <input class="form-control cursor-pointer" (click)="fromDate.toggle()"
                            placeholder="yyyy-mm-dd" name="fromDate" ngbDatepicker #fromDate="ngbDatepicker"
                            [(ngModel)]="model.completionIndicator.completionFromDate" [footerTemplate]="footerTemplateFromDate">
                          <ng-template #footerTemplateFromDate>
                            <div class="width-100 datePickerTemplate">
                              <button class="buttonDateTemplate"
                                (click)="clearDate('fromDate'); fromDate.toggle()">Clear</button>
                            </div>
                          </ng-template>
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="fromDate.toggle()"
                              type="button">
                              <fa-icon [icon]="faCalendar"></fa-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row" *ngIf="completionIndicatorSelected && model.completionIndicator?.period === 'CUSTOM'">
                        <label class="col-sm-4 control-label">To Date</label>
                        <div class="input-group date col-sm-8">
                          <input class="form-control cursor-pointer" (click)="toDate.toggle()" placeholder="yyyy-mm-dd"
                            name="toDate" ngbDatepicker #toDate="ngbDatepicker" [(ngModel)]="model.completionIndicator.completionToDate"
                            [footerTemplate]="footerTemplateToDate">
                          <ng-template #footerTemplateToDate>
                            <div class="width-100 datePickerTemplate">
                              <button class="buttonDateTemplate"
                                (click)="clearDate('toDate'); toDate.toggle()">Clear</button>
                            </div>
                          </ng-template>
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="toDate.toggle()" type="button">
                              <fa-icon [icon]="faCalendar"></fa-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <ngb-accordion #acc="ngbAccordion" [closeOthers]="true"
                          *ngIf="completionIndicatorSelected && model.completionIndicator?.completionPromoCodes">
                          <ngb-panel
                            *ngFor="let promoCode of model.completionIndicator.completionPromoCodes.completionPromoCode;  let i = index">
                            <ng-template ngbPanelHeader>
                              <div class="d-flex align-items-center justify-content-between">
                                <div>Promotion Code {{promoCode.promotionCode? '- '+ promoCode.promotionCode:''}}</div>
                                <div>
                                  <button ngbPanelToggle class="btn btn-dark-blue" (click)="editPromoCode(i)" title="Edit">
                                    <fa-icon
                                      [icon]="i === openFieldChevronList.promoCodeIndex ? faChevronUp : faChevronDown">
                                    </fa-icon>
                                  </button>
                                  <button class="btn btn-attention" (click)="removePromoCode($index)" title="Remove">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                  </button>
                                </div>
                              </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                              <h2>
                                {{promoCode.promotionCode}}
                              </h2>
                              <app-promotion-code-form [index]="i"
                                [promoCodeFormModel]="model.completionIndicator.completionPromoCodes.completionPromoCode[i]"
                                (promoCodeEvent)="handlePromoCodeEvent($event)"></app-promotion-code-form>
                            </ng-template>
                          </ngb-panel>
                        </ngb-accordion>
                        <div *ngIf="!model.completionIndicator?.useChildren && completionIndicatorSelected">
                          <h2>New Promotion Code</h2>
                          <app-promotion-code-form [index]="null" (promoCodeEvent)="handlePromoCodeEvent($event)">
                          </app-promotion-code-form>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="ibox">
                  <div class="display-title border-bottom" (click)="resizeIbox($event,4)">
                    <h4 class="user-select-none">Results Indicator</h4>
                    <div class="ibox-tools">
                      <a>
                        <fa-icon
                        [icon]="expandedLookup[4] ? faChevronUp : faChevronDown">
                      </fa-icon>
                      </a>
                    </div>
                  </div>
                  <form #f="ngForm" name="form" *ngIf="model.resultsIndicator">
                    <div class="ibox-content" [@expandCollapse] = "expandedLookup[4] ? 'open': 'close'" >
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Results Indicator</label>
                        <div class="col-sm-8">
                          <input class="mt-2" type="checkbox" name="showResults" [(ngModel)]="model.resultsIndicator.showResults" />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Results Label</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" name="resultsLabel"
                            [(ngModel)]="model.resultsIndicator.resultsLabel">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Results URL</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" name="resultsUrl"
                            [(ngModel)]="model.resultsIndicator.resultsUrl">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label">Results Icon</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" name="resultsIcon"
                            [(ngModel)]="model.resultsIndicator.resultsIcon">
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="ibox collapsed border-bottom ibox-borders-without-top">
                  <div class="display-title collapse-link display-block">
                    <h2 class="user-select-none"> Generate Display XML </h2>
                    <div class="form-group">
                      <div class="col-sm-12 display-template-buttons">
                        <button *ngIf="!sponsorshipTag" class="btn btn-white mr-2" (click)="cancelDisplayXmlEdit()"
                          title="Cancel">
                          <fa-icon [icon]="faTimes"></fa-icon>
                        </button>
                        <button id="create-xml" class="btn btn-dark-blue" type="button" (click)="createXML()"
                          title="Save XML" [disabled]="disableTemplateEdit && selectedTemplate">
                          <fa-icon [icon]="faSave"></fa-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-lg-12">
                <div class="ibox">
                  <div class="display-title border-bottom" (click)="resizeIbox($event,5)">
                    <h4 class="user-select-none">HTML View</h4>
                    <div class="ibox-tools">
                      <a>
                        <fa-icon
                        [icon]="expandedLookup[5] ? faChevronUp : faChevronDown">
                      </fa-icon>
                      </a>
                    </div>
                  </div>
                  <div class="ibox-content" [@expandCollapse] = "expandedLookup[5] ? 'open': 'close'" >
                    <div [ngClass]="expandedLookup[5]  ? '' : 'invisible'">
                    <div class="row mb-2">
                      <button id="preview-xml" class="btn btn-dark-blue" type="button" (click)="previewHTML()"
                        title="Refresh">
                        <fa-icon [icon]="faSync"></fa-icon>
                      </button>
                      <small class="p-2">Click "Refresh" to update.</small>
                    </div>
                    <div class="row mb-2 view-desktop">
                      <button class="btn btn-dark-blue" type="button" (click)="showDesktop()" title="View Desktop">
                        <fa-icon [icon]="faDesktop"></fa-icon>
                      </button>
                      <small class="p-2">Showing Mobile</small>
                    </div>
                    <div class="row">
                      <div id="mobile-promotion" class="col-sm-12">
                        <div class="image-points">
                          <div class="mobile-image-container promo-image"></div>
                          <div class="mobile-points" [hidden]="!currencyValue">
                            <p>
                              <span [hidden]="hideDollarSign">$</span> {{model.displayAmount}}
                            </p>
                            <p class="mobile-currency" [hidden]="!hideDollarSign">{{model.displayCurrency}}</p>
                          </div>
                          <div class="mobile-reward-image" [hidden]="currencyValue">
                            <img src="{{model.displayRewardImageUrl}}" alt="Icon for non-currency award">
                          </div>
                        </div>
                        <div class="details">
                          <p class="mobile-title">{{model.title}}</p>
                          <p class="mobile-subtitle">{{model.subtitle}}</p>
                          <p class="details-link" *ngIf="model.resultsIndicator"
                            href="{{model.resultsIndicator.resultsUrl}}" [hidden]="!model.resultsIndicator.showResults">
                            {{model.resultsIndicator.resultsLabel}}
                            <!-- TODO: -->
                            <!-- <fa-icon [icon]="model.resultsIndicator.resultsIcon"></fa-icon> -->
                          </p>
                          <p class="details-link" (click)="showFullDetails()" [hidden]="!hideDetails">
                            full details
                            <fa-icon [icon]="faChevronUp"></fa-icon>
                          </p>
                          <button class="mobile-action" [hidden]="hideAllActions">
                            <span [hidden]="!hidePromoAction">
                              {{model.videoHTML}}
                            </span>
                            <a href="model.buttonUrl" [hidden]="hidePromoAction">{{model.buttonText}}</a>
                          </button>
                        </div>
                        <!-- TODO: -->
                        <div class="mobile-description" [hidden]="hideDetails" [innerHtml]="model.description | stringToHtml">
                        </div>
                        <p id="mobile-disclaimer" [hidden]="hideDetails">
                          {{model.disclaimer}}
                        </p>
                        <div class="mobile-alternative-container" [hidden]="hideDetails"
                          *ngIf="model.alternativesConfiguration?.displayLink">
                          <span [hidden]="!model.alternativesConfiguration.displayLink">
                            <a class="reasonable-alternative">
                              {{ model.alternativesConfiguration.linkLabel }}
                              Reasonable Alternative
                            </a>
                            &nbsp;
                            <!-- TODO: -->
                            <!-- <i class="fa fa-info-circle" tooltip="{{model.alternativesConfiguration.toolTip}}"
                              tooltip-trigger="" tooltip-animation="false" tooltip-placement="bottom"></i> -->
                            <fa-icon [icon]=faInfoCircle></fa-icon>
                          </span>
                        </div>
                        <div class="col-md-12 col-sm-12 toggle-up-container" [hidden]="hideDetails">
                          <a type="button" class="float-right details-up-toggle" (click)="hideFullDetails()">
                            <span class="sr-only">Collapse content</span>
                            <fa-icon [icon]="faAngleUp" class="fa-angle-up mobile"></fa-icon>
                          </a>
                        </div>
                      </div>
                      <div id="desktop-promotion" class="promotion-container row w-100">
                        <div class="col-sm-9">
                          <div class="points" [hidden]="!currencyValue">
                            <p class="desktop-amount"><span [hidden]="hideDollarSign">$</span>{{model.displayAmount}}
                            </p>
                            <p class="desktop-currency" [hidden]="!hideDollarSign">{{model.displayCurrency}}</p>
                          </div>
                          <div [hidden]="currencyValue" class="desktop-reward-image">
                            <img src="{{model.displayRewardImageUrl}}" alt="Icon for non-currency award">
                          </div>
                          <div class="promotion-details">
                            <img src="{{model.imageUrl}}" class="hero-image" alt="image for {{model.title}}">
                            <div class="promotion-title-and-subtitle">
                              <h3>{{model.title}}</h3>
                              <div class="hidden-sm">{{model.subtitle}}</div>
                            </div>
                            <a class="details-toggle mr-2" (click)="showFullDetails()" [hidden]="!hideDetails">
                              full details
                              <fa-icon [icon]="faChevronDown"></fa-icon>
                            </a>
                            <a class="results-button" href="model.resultsIndicator.resultsUrl"
                              *ngIf="model.alternativesConfiguration?.displayLink"
                              [hidden]="!model.resultsIndicator.showResults">
                              {{model.resultsIndicator.resultsLabel}}
                              <!-- TODO: -->
                              <!-- <fa-icon [icon]="model.resultsIndicator.resultsIcon"></fa-icon> -->
                            </a>
                          </div>
                          <div class="desktop-description" [hidden]="hideDetails">
                            <div [innerHtml]="model.description | stringToHtml">
                            </div>
                            <p class="desktop-disclaimer">
                              {{model.disclaimer}}
                            </p>
                          </div>
                          <div class="reasonable-alternative-container" [hidden]="hideDetails"
                            *ngIf="model.alternativesConfiguration?.displayLink">
                            <span [hidden]="!model.alternativesConfiguration.displayLink">
                              <a class="reasonable-alternative">
                                {{ model.alternativesConfiguration.linkLabel }}
                                Reasonable Alternative
                              </a>
                              <!-- TODO: -->
                              <!-- <i class="fa fa-info-circle" tooltip="{{model.alternativesConfiguration.toolTip}}"
                                tooltip-trigger="" tooltip-animation="false" tooltip-placement="bottom"></i> -->
                              <fa-icon [icon]=faInfoCircle></fa-icon>
                            </span>
                          </div>
                        </div>
                         <div class="col-sm-3 action-item" [hidden]="hideAllActions">
                          <span [hidden]="!hidePromoAction"> {{videoHTML}} </span>
                          <a class="promotion-action-item" href="buttonText"
                            [hidden]="hidePromoAction">{{buttonText}}</a>
                        </div>
                        <div [hidden]="hideDetails" class="col-md-12 col-sm-12 toggle-up-container">
                          <a (click)="hideFullDetails()" type="button" class="float-right">
                            <span>Collapse content</span>
                            <fa-icon [icon]="faAngleUp"></fa-icon>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="ibox">
                  <div class="display-title border-bottom" (click)="resizeIbox($event,6)">
                    <h4 class="user-select-none">Advanced View</h4>
                    <div class="ibox-tools">
                      <a>
                        <fa-icon
                        [icon]="expandedLookup[6] ? faChevronUp : faChevronDown">
                      </fa-icon>
                      </a>
                    </div>
                  </div>
                  <div [@expandCollapse] = "expandedLookup[6] ? 'open': 'close'"class="ibox-content xml-preview">
                    <div class="row mb-2">
                      <button id="preview-xml" class="btn btn-dark-blue" type="button" (click)="previewXML()"
                        title="Refresh">
                        <fa-icon [icon]="faSync"></fa-icon>
                      </button>
                      <small class="p-2">Click "Refresh" to update.</small>
                    </div>
                    <div class="row" *ngIf="showJSON">
                      <pre class="full-width">
                        {{rawJSON}}
                      </pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</div>
</div>
</div>
