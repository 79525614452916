<div>
  <form>
    <label>The number of rewards:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="countCap !== null">
        <div *ngFor="let cntCap of countCap; let i=index" class="border-card-padding form-group col-lg-12">
          <button class="btn btn-attention float-right" type="button" (click)="removeCap(cntCap)" title="Remove Cap">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label>for the current:</label>
              <select name="period_{{cntCap.capValue}}" class="form-control" [(ngModel)]="cntCap.capPeriod" (ngModelChange)="updateCaps()">
                <option *ngFor="let period of periods" [selected]="cntCap.capPeriod === period.value" [value]="period.value">{{period.text}}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label" for="thresholdVal_{{cntCap.capValue}}">caps at (maximum number):</label>
              <input class="form-control m-b" type="number" id="thresholdVal_{{cntCap.capValue}}" name="thresholdValue" [(ngModel)]="cntCap.capValue" (ngModelChange)="updateCaps()" />
            </div>
            <div class="form-group">
              <a class="control-label" ng-class="{'disabled-link': readOnly}"
                 (click)="regexSearchModal('PROMOTIONS', { object: cntCap, name: 'promotionCodes' }, {windowClass: 'wider-modal-window'}, false, false, i)"
                 title="Search for Promotions">across Promotions (leave blank for the current promotion or use a comma separated list):</a>  <input class="form-control m-b" type="text" id="promoCodes_{{cntCap.capValue}}" name="promotionCodes" [(ngModel)]="cntCap.promotionCodes" (ngModelChange)="updateCaps()" />
            </div>
            <div class="form-group">
              <a class="control-label" ng-class="{'disabled-link': readOnly}"
                 (click)="regexSearchModal('BEHAVIORS', { object: cntCap, name: 'behaviorCodes' }, {windowClass: 'wider-modal-window'}, false, false, i)"
                 title="Search for Behaviors">limited to Behaviors with Codes (leave blank for any or use a comma separated list):</a>  <input class="form-control m-b" type="text" id="behCodes_{{cntCap.capValue}}" name="behaviorCodes" [(ngModel)]="cntCap.behaviorCodes" (ngModelChange)="updateCaps()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="previousPeriodCount_{{cntCap.capValue}}">How many previous periods should be included in the time span (leave empty for only the current period):</label>
              <input class="form-control m-b" type="number" id="previousPeriodCount_{{cntCap.capValue}}" name="previousPeriodCount" [(ngModel)]="cntCap.previousPeriodCount" (ngModelChange)="updateCaps()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="relationshipName_{{cntCap.capValue}}">Include counts from members related by (leave blank for current member only or use a comma separated list):</label>
              <input class="form-control m-b" type="text" id="relationshipName_{{cntCap.capValue}}" name="relationshipName" [(ngModel)]="cntCap.relationshipName" (ngModelChange)="updateCaps()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="friendlyMessage_{{cntCap.capValue}}">Message:</label>
              <input class="form-control m-b" type="text" id="friendlyMessage_{{cntCap.capValue}}" name="friendlyMessage" [(ngModel)]="cntCap.friendlyMessage" (ngModelChange)="updateCaps()" />
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initCapObject()"><fa-icon [icon]="faPlus"></fa-icon> Add Reward Count Cap</button>
      </div>
    </div>
  </form>
</div>
