<div>
    <form> <!-- ng-if="selectTrigger === 'primaryBehavior'">-->
      <label>Apply these rules to any Behavior:</label>
      <div class="form-group">
        <a class="control-label" ng-class="{'disabled-link': readOnly}"
           (click)="regexSearchModal('BEHAVIORS', { object: triggers.primaryBehaviorOrRewardedBehavior[0], name: 'behaviorCode' }, {windowClass: 'wider-modal-window'}, true, false)"
           title="Search for Behaviors">with a Behavior Code matching:</a>
        <input class="form-control m-b" type="text" id="behaviorCode" name="behaviorCode"
               [(ngModel)]="triggers.primaryBehaviorOrRewardedBehavior[0].behaviorCode"
               (ngModelChange)="updateTriggers()" />
      </div>
      <div class="form-group">
        <a class="control-label" ng-class="{'disabled-link': readOnly}" (click)="regexSearchModal('LOCATIONS', { object: triggers.primaryBehaviorOrRewardedBehavior[0], name: 'locationCode' }, {windowClass: 'wider-modal-window'}, true, false)" title="Search for Location">from a Location or Source of (leave blank for Any):</a>
        <input class="form-control m-b" type="text" id="locationCode" name="locationCode"
               [(ngModel)]="triggers.primaryBehaviorOrRewardedBehavior[0].locationCode"
               (ngModelChange)="updateTriggers()" />
      </div>
      <div class="form-group">
        <a class="control-label" ng-class="{'disabled-link': readOnly}" (click)="regexSearchModal('INSTRUMENTTYPES', { object: triggers.primaryBehaviorOrRewardedBehavior[0], name: 'instrumentTypeCode' }, {windowClass: 'wider-modal-window'}, true, false)" title="Search for Instrument Type">submitted on an Instrument of Type (leave blank for Any):</a>
        <input class="form-control m-b" type="text" id="instrumentType" name="instrumentType"
               [(ngModel)]="triggers.primaryBehaviorOrRewardedBehavior[0].instrumentTypeCode"
               (ngModelChange)="updateTriggers()" />
      </div>
    </form>
</div>
